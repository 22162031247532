.optionLabel {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.countDiv {
    width: 1rem !important;
    height: 1rem !important;
    background-color: #1d575f;
    color: white;
    text-align: center;
    padding-bottom: 1.3rem;
    border-radius: 20%;
}

.countLabel {
    margin: auto;
}