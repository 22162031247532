.alignTextCenter {
    width: 100%;
    text-align: center;
}

.alignTextRight {
    width: 100%;
    text-align: right;
}

.alignTextLeft {
    width: 100%;
    text-align: left;
}

.header {
    margin-left: 0.1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.header:hover {
    background-color: #ececec;
}

.headerText {
    margin: auto;
    margin-left: 2rem;
    cursor: pointer;
}

.headerCheckbox {
    margin-left: 16px !important;
}