.alignTextCenter {
    width: 100%;
    text-align: center;
}

.alignTextRight {
    width: 100%;
    text-align: right;
}

.alignTextLeft {
    width: 100%;
    text-align: left;
}

.cartera {
    width: 100%;
}

@media (max-width: 1160px) {
    .cartera {
        min-width: 1160px !important;
        font-size: 12px !important;
    }
}