.dist-error {
    padding-right: 1.7rem !important;
}

@media (max-width: 460px) {
    .persona-form {
        padding-left: 2.8rem !important;
    }
}

@media (min-width: 460.1px) {
    .persona-form {
        padding-left: 3rem !important;
    }
}


/* ----------------- LABELS ----------------- */

.label-link {
    color: #51cbce !important;
    font-size: 14px !important;
    font-family: "Barlow", 'Montserrat', Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    cursor: pointer !important;
    user-select: none;
    margin-top: 0.4rem;
}

.label-link:hover {
    text-decoration: underline currentcolor !important;
    cursor: pointer !important;
}

.p-title-view {
    font-size: '1.1rem' !important;
    margin: 0 !important;
    font-family: "Barlow", 'Montserrat', Arial, sans-serif !important;
}

.label-detail-view {
    font-size: '0.9rem' !important;
    color: #9A9A9A !important;
    font-family: "Barlow", 'Montserrat', Arial, sans-serif !important;
}


/* ----------------- CEDULA-OWNERSHIP ----------------- */

@media (max-width: 1199.9px) {
    .left-padding {
        margin-left: 25% !important;
    }
}

@media (max-width: 992px) {
    .left-padding {
        margin-left: 25% !important;
    }
}

@media (max-width: 768px) {
    .left-padding {
        margin-left: 18% !important;
    }
}

@media (max-width: 576px) {
    .left-padding {
        margin-left: 0% !important;
    }
}

@media (min-width: 1200px) and (max-width: 1260px) {
    .font-mini {
        width: 4rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0px !important;
    }
    .lender-field-ownership {
        min-width: 5rem !important;
        width: 5rem !important;
    }
}


/* ----------------- BOTONES ----------------- */

.button-alapar-success {
    background-color: #1d575f;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-success:hover {
    background-color: #31818b !important;
}

.button-alapar-danger {
    background-color: #ae0101;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-danger:hover {
    background-color: #820404 !important;
}

.table-alapar-title-default {
    color: #12383c;
    font-size: 14px !important;
}

.table-alapar-text-default {
    font-size: 14px !important;
}

.button-alapar-cancel {
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 5px 11px;
    border-color: gray !important;
    color: gray !important;
    border: 1px solid !important;
}

.button-alapar-cancel:hover {
    background-color: rgb(120, 120, 120) !important;
    border-color: rgb(86, 85, 85) !important;
    color: white !important;
    border: 1px solid transparent !important;
}

.button-alapar-warning {
    background-color: #f4b907;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    padding: 6px 12px;
}

.button-alapar-warning:hover {
    background-color: #f0ad4e !important;
}

.button-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.button-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.navbar-brand {
    text-transform: none !important;
}